@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@900&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Roboto', sans-serif;
}

.container {
    margin: 0 auto;
    padding: 0 15px;
}

body {
    /*background: rgba(0, 0, 0, 0.6);*/
}

body.hide-scroll {
    overflow: auto hidden;
    padding-right: 17px;
    touch-action: none;
    -ms-touch-action: none;
}

@media screen and (max-width: 768px) {
    body.hide-scroll {
        padding-right: 0;
    }
}

.logo {
    filter: brightness(1.5) hue-rotate(200deg)
}

.logo-name {
    font-family: 'Noto Sans', sans-serif;
}

.search-bar::placeholder {
    color: #414141;
}

.footer {
    background: #FFF;
    box-shadow: 0px -4px 10px 0px rgba(0, 0, 0, 0.03);
}

.footer-icon {
    border-radius: 6px;
    background: #FFF;
    box-shadow: 0px 0px 7px 0px rgba(94, 92, 92, 0.10), 0px 4px 16px 0px rgba(55, 55, 55, 0.08);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.3s ease;
}

.footer-icon:hover {
    background: #245462;
}

.footer-icon path {
    transition: 0.3s ease;
}

.footer-icon:hover path {
    fill: #fff;
}

.search-bar-modal {
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.navbar-categories {
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.07);
    overflow-x: auto;
    overflow-y: hidden;
}

.navbar-categories::-webkit-scrollbar {
    display: none;
}

/* Track */
.navbar-categories::-webkit-scrollbar-track {
}

/* Handle */
.navbar-categories::-webkit-scrollbar-thumb {
}

.nav-sub-categories {
    box-shadow: 0px 2px 4px 0px #00000012;
    background: #FFFFFF;
}

.banner-title {
    font-family: 'Playfair Display', serif;
}

.product-img-big {
    min-height: 370px !important;
    max-height: 370px !important;
}

.color-radio {
    display: inline-flex;
    margin-right: 10px;
    cursor: pointer;
    border-radius: 50%;
    border: 2px solid transparent;
}

.color-radio input {
    display: none;
}

.color-dot {
    display: inline-block;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    margin: 3px;
}

@media screen and (max-width: 850px) {
    .color-dot {
        width: 25px;
        height: 25px;
        margin: 2px;
    }

    .color-radio {
        margin-right: 5px;
    }
}

.category-filter-side {
    box-shadow: 0px 2px 4px 0px #00000012;
}

.footer-navigation {
    @media screen and (max-width: 389px) {
        margin: 30px 0 0 0;
    }
}

.footer-copyright {
    @media screen and (max-width: 527px) {
        text-align: center;
        justify-content: center;

        & span:nth-child(1) {
            margin-bottom: 10px;
        }
    }
}

.productSwiper .swiper-slide {
    max-width: 125px !important;
}

@media screen and (min-width: 541px) {
    .productSwiper .swiper-slide {
        max-width: 125px !important;
        width: 125px !important;
    }
}

.productSwiper .swiper-slide img {
    width: 100% !important;
    object-fit: contain !important;
}