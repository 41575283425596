.banner-mobile {
    display: none;
}

@media screen and (max-width: 600px) {
    .banner-mobile.banner {
        display: block;
        height: 300px !important;
    }
}

@media screen and (max-width: 550px) {
    .banner-mobile.banner {
        display: block;
        height: 260px !important;
    }
}

@media screen and (max-width: 460px) {
    .banner-mobile.banner {
        display: block;
        height: 190px !important;
        color: #f1f6f1
    }
}

@media screen and (max-width: 400px) {
    .banner-mobile.banner {
        display: block;
        height: 170px !important;
        color: #f1f6f1
    }
}

.banner {
    transform: translateZ(0);
    width: 100%;
    height: 400px !important;
}

.banner-first {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

@media screen and (max-width: 600px) {
    .banner {
        display: none;
    }
}

@media screen and (max-width: 1120px) {
    .banner {
        height: 300px !important;
    }
}

@media screen and (max-width: 930px) {
    .banner {
        height: 250px !important;
    }
}

@media screen and (max-width: 730px) {
    .banner {
        height: 200px !important;
    }
}

.banner .swiper {
    width: 100%;
}

.banner .mySwiper {
    max-width: 100% !important;
    padding: 0 !important;
    height: 100% !important;
    box-shadow: 0px 5px 11px 2px rgba(0, 0, 0, 0.09);
}

.banner .mySwiper .swiper-slide {
    width: inherit !important;
    opacity: 1 !important;
    border: none !important;
}

.banner .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;

    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.banner .swiper-slide img {
    display: block;
    object-fit: cover;
    object-position: center;
    user-select: none;
}

.banner .mySwiper .swiper-slide img {
    max-width: 100% !important;
    width: 100% !important;
    height: 100% !important;
}

.button-slider-banner {
    background: rgba(157, 157, 157, 0.68);
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: white;
    transition: 0.3s ease;
}

.button-slider-banner:hover{
    background: white;
    color: rgb(157, 157, 157);
}

.button-prev {
    position: absolute;
    left: 20px;
    z-index: 2;
}

.button-next{
    position: absolute;
    right: 20px;
    z-index: 2;
}

.button-prev-img {
    position: absolute;
    left: 10px;
    z-index: 2;
    border: 1px solid rgba(128, 128, 128, 0.42);
}

.button-next-img{
    position: absolute;
    right: 10px;
    z-index: 2;
    border: 1px solid rgba(128, 128, 128, 0.42);
}